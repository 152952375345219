import { status } from "skin/event/status";
import table from "./table";
import control from "./control";

const lndLine = (obj, form, story, data, d, action) => {
  console.log("[Категория - форма] событие", obj);
  switch (obj.type) {
    case "table":
      return table(obj, form, story, data, d, action);
    case "control":
      return control(obj, form, story, data, d, action);
    default:
  }
  return status.action;
};

export default lndLine;
