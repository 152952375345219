import { useEffect, useRef, useState } from "react";
import "./style.css";

const Burger = ({ data }) => {
  // let cls = 'skin-header-burger';

  const [show, setShow] = useState(false);
  const click = (event) => {
    event.stopPropagation();
    setShow(!show);
  };
  const close = (_) => setShow(false);

  const ref = useRef();

  useEffect(() => {
    const click = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        close();
      }
    };
    setTimeout(() => {
      document.addEventListener("click", click);
    });

    return () => document.removeEventListener("click", click);
  }, []);

  return (
    <span onClick={click} style={{ backgroundImage: "url(skin/img/burger_white.svg)", backgroundRepeat: "no-repeat", width: "30px", height: "30px" }}>
      {show && (
        <div ref={ref} className="login-header-burger-drop">
          {data.map((item, i) => {
            return (
              <div className="login-header-burger-drop-item" key={item.id + i}>
                <p key={item.id}>
                  <a href={"https://flet.su" + item.href}>{item.title} </a>{" "}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </span>
  );
};

export default Burger;
