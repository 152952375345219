import { status } from "skin/event/status";
import click from "./click";
import click2 from "./click2";
import table from "./table";
import dt from "./data";
import { clear } from "./click/fn";

const msEmpAgent = (obj, ms, story, data, d, action) => {
  console.log("[Ячейки - Мастер] событие'", obj);
  if (obj.action === "setInput" && obj.name === "busyness") clear(data, true);
  if (obj.type === "btn" && obj.action === "click") {
    switch (obj.code) {
      case "btn":
        return click(obj, ms, story, data, d, action);
      case "btnA":
        return click2(obj, ms, story, data, d, action);
      default:
        return status.action;
    }
  }
  switch (obj.type) {
    case "table":
      return table(obj, ms, story, data, d, action);
    case "data":
      return dt(obj, ms, story, data, d, action);
    default:
      return status.action;
  }
};

export default msEmpAgent;
