import { message } from "skin/event";
import { status } from "skin/event/status";

const control = (obj, form, story, data, d, action) => {
  const numAct = data?.s.values?.fld?.numAct;
  const numBlk = data?.s.values?.fld?.numBlk;
  const lenAct = data?.s?.values?.table?.lndBtn?.count;
  const lenBlk = data?.s?.values?.table?.lndBlock?.count;
  if (obj.action === "add" && obj.table === "lndBtn" && numAct === -1) {
    return status.action;
  }
  if (obj.action === "add" && obj.table === "lndBlock" && numBlk === -1) {
    return status.action;
  }
  function msg(a) {
    message(`Максимальное число элементов ${a}`, "Лимит", null, "!");
  }
  if (obj.action === "add") {
    if (obj.table === "lndBtn" && lenAct >= numAct) {
      msg(numAct);
      return status.ignore;
    }
    if (obj.table === "lndBlock" && lenBlk >= numBlk) {
      msg(numBlk);
      return status.ignore;
    }
  }
  return status.action;
};

export default control;
