import React from "react";
import "./style.css";
import user from "skin/store/user";

const LogoL = ({ logo = user.logo }) => {
  let cls = ["cmp-logo-l", "logo-l"];
  cls = cls.join(" ");
  const stl = { backgroundImage: logo ? logo : `url(task/img/flet.png)` };
  return <div className={cls} style={stl} onClick={() => user.get()} />;
};

export default LogoL;
