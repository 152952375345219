import { status } from "skin/event/status";

const table = (obj, form, story, data, d, action) => {
  const key = story?.current?.table?.tableFb?.row;
  if (obj.action === "row" && obj.key === key) {
    return status.ignore;
  }
  return status.action;
};

export default table;
