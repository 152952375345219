import { message } from "skin/event";
import { status } from "skin/event/status";

const control = (obj, form, story, data, d, action) => {
  const limit = data?.s?.values?.fld?.limit;
  const count = data?.s?.values?.table?.lndBtn?.count;
  if (obj.action === "add" && obj.table === "lndBtn" && limit === -1) {
    return status.action;
  }
  if (obj.action === "add" && obj.table === "lndBtn" && count >= limit) {
    message(`Максимальное число элементов ${limit}`, "Лимит", null, "!");
    return status.ignore;
  }
  return status.action;
};

export default control;
