import { status } from "skin/event/status";
import dt from "./data";

const msAgentClnt = (obj, ms, story, data, d, action) => {
  console.log("[Регистрация домена - Мастер] событие'", obj);
    switch (obj.type) {
      case "data":
        return dt(obj, ms, story, data, d, action);
      default:
        return status.action;
    }
};

export default msAgentClnt;
