import { status } from "skin/event/status";
import { message } from "skin/event";

// Проверка адреса клиента(компании) по нажатию кнопки
const click2 = (obj, ms, story, data, d, action) => {
  const isInn = data?.s?.msAgentClnt?.values?.fld?.isINN;
  const isAdr = data?.s?.msAgentClnt?.values?.fld?.isAdr;
  const adr = data?.s?.msAgentClnt?.values?.fld?.address;
  const addr = {
    housePattern: /д \d+/,
  };
  const hasHouse = addr.housePattern.test(adr);
  
  if (!hasHouse) {
    data.setValue("isAdr", "")
    message("Введите адрес полностью (должен быть указан до дома)", "Проверка адреса ИНН", null);
  } else {
    data.setValue("isAdr", "yes")
    message("Адрес валидный", "Проверка адреса ИНН", null);
  }
  
  if (!isInn) {
    const text = ["ИНН не найден", "или не был проверен"];
    message(text, "Проверка адреса ИНН", null);
    return status.ignore;
  }
  if (!isAdr && !adr) {
    message("Не был указан адрес", "Проверка адреса ИНН", null);
    return status.ignore;
  }
  return status.ignore;
};

export default click2;
