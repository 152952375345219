import cnfSkin from "config_skin";
import tskSkin from "config_task";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import msg from "skin/data/msg";
import { message, space, stackEdit } from "skin/event";
import api from "skin/http";
import menu from "skin/store/menu";

class Data {
  s = {
    // func,
  };
  constructor(props) {
    makeAutoObservable(this);
  }

  //Получаем длинну массива экрана
  get count() {
    const a = this.s?.page?.list ?? [];
    return a.length;
  }
  get title() {
    return this.s?.book?.title ?? "Форма <не определена>";
  }
  getPage(c) {
    // Получение данных по текущей странице
    // Используется в Хедере для правильного отображения

    if (!c) return {};
    const o = this.s.page?.list[c];
    return o ?? {};
  }

  // Вернем все для таблицы по коду
  getTable(code) {
    const t = this.s.table;

    return t.find((el) => el.code === code);
  }

  // Клик на меню функций
  fncClick(id, info) {
    const obj = this.s.func ?? {};
    let a = obj.list ?? [];
    a = a.slice();
    const i = a.findIndex((el) => el._id === id);
    if (i < 0) return;
    if (a[i].type === "folder") {
      if (!menu.s.double) obj.current = id;
      if (obj.current === id) {
        a[i].open = true;
        obj.current = "";
        this.fncParent(id);
      }
      obj.current = id;
    } else {
      this.action(i, info);
    }
    this.s.list = a;
  }

  // Запуск функции на исполнение
  action(n, info) {
    const obj = this.s.func ?? {};
    let o = obj.list[n];
    if (!menu.s.double) obj.current = o._id;
    if (obj.current !== o._id) {
      obj.current = o._id;
      return;
    }
    if (!o.action) {
      alert("Действие не определено");
      return;
    }
    o.action.title = o.action.title ?? o.title;
    o = toJS(o.action);
    o.data.info = info;
    space(o, true);
  }

  // Завершить работу с функциями
  close() {
    this.func();
  }

  // Выйти в начало меню
  fncHome() {
    const obj = this.s.func ?? {};
    obj.parent = "";
    obj.current = "";
  }
  // Выйти из папки
  fncUp(id) {
    const obj = this.s.func ?? {};
    if (!obj.parent) return;
    if (id && obj.current !== id) {
      obj.current = id;
      return;
    }
    obj.current = this.s.parent;
    const a = obj.list;
    const o = a.find((el) => el._id === obj.parent);
    this.fncParent(o.parent);
  }

  // Установить нового родителя
  fncParent(id) {
    const obj = this.s.func ?? {};
    obj.parent = id ?? "";
  }

  // Функционал "Вкл/Выкл"
  func(on) {
    on = on ?? false;
    if (this.s.func) {
      this.s.func.on = on;
    }
    // Сброс переходов в меню функции
    if (on) {
      if (this.s.func) {
        this.s.func.parent = "";
        this.s.func.current = "";
      }
    }
  }

  // Смена представления таблицы
  setPage(tbl, i) {
    const t = this.s.table ?? [];
    const a = t.findIndex((el) => el.id === tbl);
    if (a === -1) return;
    const l = t[a].view?.list.length;
    const c = t[a].view?.current ?? 0;

    if (c === i - 1) return;
    if (i > l) i = l;
    if (i < 1) i = 1;
    t[a].view.current = i - 1;
  }

  // Получение структуры с сервера Luck
  getDef(value, action, story) {
    const self = this;
    var config = {
      method: "get",
      url: cnfSkin.url + "api/book",
      params: { value },
    };
    value = JSON.parse(value);
    const cnf = {
      method: "get",
      url: tskSkin.url + "api/other/menu",
    };
    Promise.all([api(config), api(cnf)])
      .then(([res, exc]) => {
        const r = res.data.result;
        const e = exc.data.result;
        r.func.list = r.func.list.filter((el) => !e.includes(el._id));
        if (r.book?.title) stackEdit(r.book.title);
        runInAction(() => {
          for (let key in r) self.s[key] = r[key];

          // Story Заполняем дефолтными данными
          story.setDef(r.book.code, r.table, value.info);

          // Data Заполняем данные по дефолту и получаем данные с сервера
          const o = {
            type: "form",
            action: "loadDef",
          };
          action(o);
        });
      })
      .catch(console.log);
  }

  // Стандартное сохранение
  save(d, story, data, action) {
    const code = story.s.current;

    if (typeof d === "string") d = JSON.parse(d);
    let value = d.info ?? [];
    value = JSON.stringify(value);

    const fd = data.getChanged(this);
    const config = {
      method: "patch",
      url: `api/save/${code}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: fd,
      params: { value },
    };
    api(config)
      .then((response) => {
        const o = {
          type: "form",
          action: "reload",
        };
        action(o);
      })
      .catch(console.log);
  }

  // Станадртная проверка
  check(story, data, action) {
    const code = story.s.current;
    const mesTitle = this.s?.book?.title ?? "Форма <не определена>";

    const fd = data.getAll(this, true);

    const config = {
      method: "post",
      url: `api/check/${code}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: fd,
    };
    api(config)
      .then(function (response) {
        const res = response.data.result;
        if (!res.ok) {
          data.setError(res.error);
          message(msg.error.fld, mesTitle, null, "!");
          return;
        }
        if (res.attention) {
          message(res.attention, mesTitle, null, "!");
          return;
        }
        const o = {
          type: "form",
          action: "save",
        };
        action(o);
      })
      .catch(console.log);
  }

  // Удаление всего документа , закрытие формы
  del(d, story, action) {
    const mesTitle = this.s?.book?.title;
    const code = story.s.current;
    if (typeof d === "string") d = JSON.parse(d);
    let value = d.info ?? [];
    value = JSON.stringify(value);

    const config = {
      method: "delete",
      url: `api/del/${code}`,
      params: { value },
    };
    api(config)
      .then(function (response) {
        const err = response.data.error;
        if (err) return message(err, mesTitle, null, "!");
        const o = {
          type: "form",
          action: "exit",
        };
        action(o);
      })
      .catch(console.log);
  }
  // Удаление подчиненного документа формы
  delRow(d, tbl, action) {
    const t = this.getTable(tbl);
    const mesTitle = t.title;
    let value = d.info ?? [];
    value = JSON.stringify(value);

    const config = {
      method: "delete",
      url: `api/del/${tbl}`,
      params: { value },
    };
    api(config)
      .then(function (response) {
        const err = response.data.error;
        if (err) return message(err, mesTitle, null, "!");
        const o = {
          type: "form",
          action: "reload",
          table: tbl,
        };
        action(o);
      })
      .catch(console.log);
  }

  add(story, data) {
    const mesTitle = this.s?.book?.title ?? "Форма <не определена>";
    const code = story.s.current;
    const d = data.getAll(this);
    const master = this.s.book.master.add;
    if (master) {
      const o = {
        type: "master",
        title: "Добавление " + mesTitle,
        data: {
          code: master,
          info: story.current.info ?? [],
          type: "form",
        },
      };
      return space(o, true, "back");
    }
    const config = {
      method: "post",
      url: `api/add/${code}`,
      data: { data: d },
    };
    api(config)
      .then((response) => {
        const r = response.data.result;
        if (r.error) return message(r.error, mesTitle, null, "!");
        const o = {
          type: "form",
          title: "Форма",
          data: {
            code: code,
            info: r,
          },
        };
        space(o, true, "back");
      })
      .catch(console.log);
  }
  addTbl(story, data, tbl) {
    const code = tbl ? tbl : story.s.current;
    const d = data.getAll(this);
    const t = this.getTable(code);
    const title = t.title;
    const master = t.master.add;
    const id = d.id._id;
    let info = story.current.info;
    if (!info || !info.length) info = id ? [id] : [];
    if (master) {
      const o = {
        type: "master",
        title: "Добавление " + title,
        data: {
          code: master,
          info,
          type: "table",
        },
      };
      return space(o, true);
    }
    const config = {
      method: "post",
      url: `api/add/${code}`,
      data: { data: d },
    };
    api(config)
      .then((response) => {
        const r = response.data.result;
        if (r.error) return message(r.error, title, null, "!");
        const o = {
          type: "form",
          title: t.form.edit.title ?? "Форма",
          data: {
            code: t.form.edit.code,
            info: r,
          },
        };
        return space(o, true);
      })
      .catch(console.log);
  }
}
const d = new Data();
export default d;
