import { status } from "skin/event/status";
import api from "skin/http";
import { npd, innIp, none, chkBusy, chkInn, selfEmp, clear } from "./fn";

// Проверка ИНН сотрудника (агента) по нажатию кнопки
const click = (obj, ms, story, data, d, action) => {
  const inn = data?.curData?.values?.fld?.inn;
  const busy = data?.curData?.values?.fld?.busyness;
  if (!busy) {
    chkBusy();
    return status.ignore;
  }
  if (busy === "Самозанятый") {
    clear(data);
    selfEmp();
    return status.ignore;
  }
  if (!inn) {
    chkInn();
    return status.ignore;
  }
  // const url = busy === "Самозанятый" ? "api/other/master/chkNpd/" : "api/other/master/chkInn/";
  const url = "api/other/master/chkInn/";
  const config = {
    method: "post",
    url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      inn,
    },
  };
  api(config)
    .then((d) => {
      if (d.data) {
        d.data.value ? innIp(d.data, data) : npd(data);
      } else {
        none(data);
      }
    })
    .catch((err) => console.error());
  return status.ignore;
};

export default click;
