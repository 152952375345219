import { status } from "skin/event/status";
import add from "./add";

const table = (obj, form, story, data, d, action) => {
  switch (obj.action) {
    case "add":
      if (obj.table === "lndBtn") {
        return add(obj, form, story, data, d, action);
      } else {
        return status.action;
      }
    default:
      return status.action;
  }
};

export default table;
