import { status } from "skin/event/status";
import api from "skin/http";

const click = (obj, form, story, data, d, action) => {
  console.log("[Заявка - форма] событие", obj);
  const id = story.current.info[0];
  const config = {
    method: "post",
    url: "api/other/form/chgSts/" + id,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  api(config)
    .then(() => {
      const o = {
        type: "form",
        action: "reload",
      };
      action(o);
    })
    .catch(console.log);
  return status.ignore;
};

export default click;
