import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import Item from "../../item";
import user from 'skin/store/user'
const ListForm = ({onClose, all, click}) => {
    const ref = useRef();


    useEffect(() => {
		user.get();
	}, []);

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

    return ( 
        <div ref={ref} className= "skin-header-burger-drop">
			{all.map((el) => 
			<div key = {el.id} className="skin-header-burger-drop-user">
				<Item key = {el.id} data={el} click = {click} />
			</div>)}
        </div>
    )	
}



export default observer(ListForm);