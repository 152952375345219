import { status } from "skin/event/status";

const setInput = (obj, ms, story, data, d, action) => {
	const v = obj.value
	const err = 'Поддомен должен содержать от 1 до 63 символов (латинских букв, цифр, дефисов)'
	const r = new RegExp('^[a-zA-Z0-9]([a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?$');
	const f = r.test(v)
	if (f) {
		data.setValue('domain2', v.toLowerCase())
		return status.action
	}
	data.setValue('domain', v)
	data.setError({domain: {err}})
	return status.ignore;
};

export default setInput;
