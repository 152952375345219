import { status } from "skin/event/status";
import click from "./click";

const action = (obj, form, story, data, d, action) => {
  switch (obj.action) {
    case "click":
      return click(obj, form, story, data, d, action);
    default:
  }
  return status.action;
};

export default action;
