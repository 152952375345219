import { message } from "skin/event";

const capitalize = (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();

// Функция для самозанятых
function npd(data) {
  txt("Самозанятый");
  clear(data);
}

// Функция для ИП или ООО
function innIp(d, data) {
  d.data.type === "LEGAL" ? txt("OOO") : txt("ИП");
  const o = d.data;
  const postName = o?.management?.post;
  const role = postName ? capitalize(postName) : "Руководитель";
  const kl = o.address.data;
  const manager = o.type === "INDIVIDUAL" ? `${o.fio.surname} ${o.fio.name} ${o.fio.patronymic}` : o.management?.name;
  const type = o.type === "LEGAL" ? o.address?.unrestricted_value : "";
  data.setValue("type", o.type);
  data.setValue("offName", d.value);
  data.setValue("manager", manager);
  data.setValue("managerTitle", role);
  data.setValue("ogrn", o.ogrn);
  data.setValue("kpp", o.kpp);
  data.setValue("kladrId", kl.city_kladr_id ?? kl.settlement_kladr_id);
  data.setValue("address", type);
  data.setValue("isINN", "yes");
  data.setValue("isAdr", o.type === "LEGAL" ? "yes" : "");
}

// Функция если данные не были найдены
function none(data) {
  const result = ["ИНН не найден", "или отсутствует"];
  message(result, "Проверка ИНН", null);
  clear(data);
}

// Функция вызова окна сообщения
function txt(a) {
  const text = ["ИНН подтвержден", `статус: ${a}`];
  message(text, "Проверка ИНН", null);
}

// Проверка выбора типа занятости
function chkBusy() {
  const text = ["Не выбран тип занятости"];
  message(text, "Проверка ИНН", null);
}

// Сообщение для самозянятого
function selfEmp() {
  const text = ["Для самозанятых ИНН не проверяется"];
  message(text, "Проверка ИНН", null);
}

// Проверка ввода инн
function chkInn() {
  const text = ["Не введен инн"];
  message(text, "Проверка ИНН", null);
}

// Очистка полей
function clear(data, self = false) {
  const list = ["type", "offName", "manager", "managerTitle", "ogrn", "kpp", "kladrId", "address", "isINN", "isAdr"];
  for (const el of list) {
    data.setValue(el, "");
  }
  if (self) data.setValue("inn", "");
}

export { npd, innIp, none, chkBusy, chkInn, selfEmp, clear };
