import { space } from "skin/event";
import { status } from "skin/event/status";

function add(obj, form, story, data, d, action) {
  const code = form?.s?.book?.code;
  const id = data?.s?.values?.fld?.id;
  const o = {
    type: "master",
    data: {
      code: "msLndBtn",
      info: [id, code],
    },
  };
  space(o, true);
  return status.ignore;
}

export default add;
