import { makeAutoObservable } from "mobx";
import def from "./def";
import delFile from "./del_file";
import pickCell from "./pick_cell";
import plgList from "./plg_list";
import prnCell from "./print_cell";
import printPlc from "./print_plc";
import prnBox from "./prn_box";
// import invoice from './invoice';
import arrToPac from "./arr_pac";
import arrToRem from "./arr_rem";
import codeDown from "./code/download";
import prdCopy from "./copy_prd";
import msCtgUp from "./ctg_up";
import defTar from "./def_tar";
import genFact from "./gen/fact";
import genNo from "./gen/no";
import genPcg from "./gen/pcg";
import genStep from "./gen/step";
import genCat from "./gen_cat";
import makeProd from "./make_prod";
import msAct from "./ms_act";
import msCmpPice from "./ms_cmp_price";
import msCSV from "./ms_csv";
import msCtgDwnl from "./ms_ctg_dwnl";
import msDStatus from "./ms_d_status";
import msData from "./ms_data";
import msDeliv from "./ms_deliv";
import msExMrkt from "./ms_ex";
import msFriend from "./ms_friend";
import msMod from "./ms_mod";
import msNoteUp from "./ms_note_up";
import msPickup from "./ms_pickup";
import msPrdWriteoff from "./ms_prd_writeoff";
import msProdMove from "./ms_prod_move";
import msRfmGroup from "./ms_rfm_group";
import msSegment from "./ms_segment";
import { default as msSelectMod, default as msSelectModAdm, default as msSelectModCli } from "./ms_select_mod_adm";
import msPack from "./pac";
import msPacList from "./pac_list";
import pacMaster from "./pac_master";
import parList from "./par_list";
import prnCode from "./print_code";
import msProA from "./pro_a";
import msProT from "./pro_t";
import msProdDwnl from "./prod/ms_prod_dwnl";
import msProdUp from "./prod/ms_prod_up";
import msProd from "./product";
import msProImg from "./property";
import remain from "./remain";
import rewDown from "./review/rew_down";
import rewUp from "./review/rew_up";
import rfmCompare from "./rfm_compare";
import sales from "./sales";
import syncFrom from "./sync_from";
import syncTo from "./sync_to";
import teg from "./teg";
import topup from "./topup";
import usAct from "./us_act";
import msCmpPrice from "./ms_cmp_price";
import colorScheme from "./color_scheme";
import profit from "./profit";
import msAgentClnt from "./agent_client";
import msEmpAgent from "./agent_emp";
import regDomain from "./reg_domain";

class Data {
  s = {
    def,
    delFile,
    pickCell,
    plgList,
    printPlc,
    prnBox,
    // invoice,
    prnCell,
    arrToRem,
    teg,
    sales,
    usAct,
    pacMaster,
    msPacList,
    defTar,
    makeProd,
    parList,
    prnCode,
    msDeliv,
    syncFrom,
    syncTo,
    arrToPac,
    msPack,
    msMod,
    msProImg,
    msProT,
    msProA,
    genFact,
    genPcg,
    genNo,
    genStep,
    genCat,
    codeDown,
    msCSV,
    prdCopy,
    msFriend,
    msPrdWriteoff,
    msCtgDwnl,
    msCtgUp,
    msRfmGroup,
    msSegment,
    msData,
    rewDown,
    rewUp,
    msProdDwnl,
    msProdUp,
    msNoteUp,
    rfmCompare,
    msDStatus,
    msProd,
    msPickup,
    msExMrkt,
    msSelectModAdm,
    msSelectModCli,
    msSelectMod,
    topup,
    remain,
    msProdMove,
    msAct,
    msCmpPrice,
    colorScheme,
    profit,
    msAgentClnt,
    msEmpAgent,
    regDomain,
  };
  constructor(props) {
    makeAutoObservable(this);
  }
}

const data = new Data();
export default data;
