import api from "skin/http";
import { status } from "skin/event/status";

const setInput = (obj, ms, story, data, d, action) => {
  setTimeout((_) => {
    const adr = data.curData.values.fld.address;
    const config = {
      method: "post",
      url: "api/other/master/addr/find",
      data: { query: adr },
      headers: {
        nowait: true,
      },
    };
    api(config)
      .then((res) => {
        const list = data.curData.values.table.addresses.list;
        list.forEach((el) => data.delRow(ms, "addresses", el.key));
        res.data.forEach((el) => {
          data.addRow(ms, "addresses", {
            key: el.value,
            list: ["", el.value, el.data.geo_lat, el.data.geo_lon],
          });
        });
      })
      .catch((_) => {});
  }, 1000);
  return status.action;
};

export default setInput;
