import { status } from "skin/event/status";
import { message } from "skin/event";
import { chkBusy } from "../click/fn";

// Проверка адреса клиента(компании) по нажатию кнопки
const click2 = (obj, ms, story, data, d, action) => {
  const busy = data?.curData?.values?.fld?.busyness;
  if (!busy) {
    chkBusy();
    return status.ignore;
  }
  const isInn = data?.curData?.values?.fld?.isINN;
  const isAdr = data?.curData?.values?.fld?.isAdr;
  const adr = data?.curData?.values?.fld?.address;
  const addr = {
    housePattern: /д \d+/,
  };
  const hasHouse = addr.housePattern.test(adr);
  if (!isInn && busy === "ИП или ООО") {
    sendMess("ИНН не найден, или не был проверен");
    return status.ignore;
  }
  if (!isAdr && !adr) {
    sendMess("Не был указан адрес");
    return status.ignore;
  }
  if (!hasHouse) {
    data.setValue("isAdr", "");
    sendMess("Введите адрес полностью (должен быть указан до дома)");
    return status.ignore;
  } else {
    data.setValue("isAdr", "yes");
    sendMess("Адрес валидный");
  }
  return status.ignore;
};

export default click2;

// Сообщения при проверке адреса
function sendMess(a, b = "Проверка адреса ИНН") {
  message(a, b, null);
}
