import { status } from "skin/event/status";
import { message } from "skin/event";
import api from "skin/http";

const capitalize = (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase();

// Проверка ИНН клиента(компании) по нажатию кнопки
const click = (obj, ms, story, data, d, action) => {
  console.log("[Ячейки - Мастер] событие", obj);
  const inn = data?.s?.msAgentClnt?.values?.fld?.inn;

  const config = {
    method: "post",
    url: "api/other/master/chkInn/",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: {
      inn,
    },
  };
  api(config)
    .then((d) => {
      if (d.data) {
        const o = d.data;
        const postName = o.data.management?.post;
        const role = postName ? capitalize(postName) : "Руководитель";
        const kl = o.data.address.data;
        const manager = o.data.type === "INDIVIDUAL" ? `${o.data.fio.surname} ${o.data.fio.name} ${o.data.fio.patronymic}` : o.data.management?.name;
        const type = o.data.type === "LEGAL" ? o.data.address?.unrestricted_value : "";
        data.setValue("type", o.data.type);
        data.setValue("offName", o.value);
        data.setValue("manager", manager);
        data.setValue("managerTitle", role);
        data.setValue("ogrn", o.data.ogrn);
        data.setValue("kpp", o.data.kpp);
        data.setValue("kladrId", kl.city_kladr_id ?? kl.settlement_kladr_id);
        data.setValue("address", type);
        data.setValue("isINN", "yes")
        data.setValue("isAdr", o.data.type === "LEGAL" ? "yes" : "")
      } else {
        const result = ["ИНН не найден", "или отсутствует"];
        message(result, "Проверка ИНН", null);
        const list = ["type", "offName", "manager", "managerTitle", "ogrn", "kpp", "kladrId", "address", "isINN", "isAdr"];
        for (const el of list) {
          data.setValue(el, "");
        }
      }
    })
    .catch(console.log);
  return status.ignore;
};

export default click;
