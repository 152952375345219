import { status } from "skin/event/status";
import table from "./table";

const feedback = (obj, form, story, data, d, action) => {
  console.log("[Категория - форма] событие", obj);
  switch (obj.type) {
    case "table":
      return table(obj, form, story, data, d, action);
    default:
      return status.action;
  }
};

export default feedback;
